import { queue } from "jquery";
import { IrUtils } from "../utils/utils.es13.js";

/**
 * Enum for TTS Sources (used for weighting interactions between commands)
 * @readonly
 * @enum {Number}
 */
const TTS_SOURCES = {
	AI_INTRO: 4,
	MOVEMENT_TO_DIFFERENT_LISTING: 3,
	MOVEMENT: 2,
	AI_OUTRO: 1,
	UNSPECIFIED: 0
}

class TextToSpeechInstance {
	constructor(text, options) {
		const defaultOptions = {
			source: TTS_SOURCES.UNSPECIFIED,
			voice: 'echo'
		}
		this._config = $.extend({}, defaultOptions, options);
		this.text = text;
		this.voice = this._config.voice;
		this.cacheKey = `${this.voice}:${this.text}`;
		this.source = this._config.source;
	}
}

class TextToSpeechSpeaker {
	constructor(options) {
		const defaultOptions = {
			aiService: null,
			audioControlContainer: document.body,
			enabled: true,
			paused: true,
			pauseSource: TTS_SOURCES.AI_INTRO
		};
		this._config = $.extend({}, defaultOptions, options);

		this._aiService = this._config.aiService;
		this._control = null;
		this._currentInstance = null;
		this._enabled = this._config.enabled;
		this._paused = this._config.paused;
		this._pauseUntilSource = this._config.pauseSource;
		this._queue = [];
		this._urlCache = new Map(); // voice:text -> url
		this.onEnded = (successful) => { return false };
		this.onStarted = () => { return false };

		this._initAudioControl();
	}

	_initAudioControl() {
		const $container = $(this._config.audioControlContainer);
		const control = new Audio();
		this._control = control;
		control.onended = () => { this._onEnd(true); }
		$container.append(this._control);
	}

	get enabled() {
		return this._enabled;
	}

	set enabled(val) {
		this.setEnabled(val);
		return this._enabled;
	}

	async _cacheAudioUrl(instance) {
		if (!this._urlCache.has(instance.cacheKey)) {
			const url = await this._aiService.getTtsAudioUrl(instance.text, instance.voice);
			this._urlCache.set(instance.cacheKey, url);
		}
		return this;
	}

	_clear() {
		this._queue = [];
		this._control.removeAttribute('src');
	}

	_coalesceSource(source) {
		source = +source;
		return isNaN(source) ? TTS_SOURCES.UNSPECIFIED : Math.floor(Math.abs(source));
	}

	_onEnd(success) {
		this._currentInstance = null;
		this.onEnded(success);
		this._speakNext();
	}

	_onStart() {
		this.onStarted();
	}

	/**
	 * Pause the current instance. Note that this does NOT trigger onEnded, as
	 * it's meant to be used for cases where some other UI action (e.g. STT Input)
	 * interrupts the current playback.
	 * @param {TTS_SOURCES?} source The optional source requesting the pause.
	 * @returns this
	 */
	pause(source) {
		this._control?.pause();
		this._paused = true;
		source = this._coalesceSource(source);
		if (!this._pauseUntilSource || this._sourceOverrides(source, this._pauseUntilSource)) {
			this._pauseUntilSource = source;
		}
		return this;
	}

	_log() {
		const timeCode = Date.now() * 0.001;
		console.debug('[TTS]', ...arguments, timeCode);
	}

	/**
	 * Play the audio for a given instance, checking to ensure it's the current one
	 * and that we're not in an externally driven pause state.
	 * @param {TextToSpeechInstance} instance
	 * @returns
	 */
	_play(instance) {
		if (this._shouldNotPlay(instance)) {
			this._onEnd(false);
			return this;
		}

		if (this._paused) {
			this._paused = false;
			this._pauseUntilSource = TTS_SOURCES.UNSPECIFIED;
		}

		if (instance?.cacheKey && instance?.cacheKey == this._currentInstance?.cacheKey) {
			const audioUrl = this._urlCache.get(instance.cacheKey);
			this._control.pause();
			this._control.src = audioUrl;
			this._control.currentTime = 0;
			try {
				this._onStart();
				this._control.play();
			} catch (_) {
				this._onEnd(false);
			}
		}
		return this;
	}

	/**
	 * Resume playback of the current instance. Note that this does NOT trigger onEnded,
	 * as it's meant to be used for cases where some other UI action (e.g. STT Input)
	 * has interrupted the current playback.
	 * @param {TTS_SOURCES?} source The optional source requesting the resume.
	 * @returns this
	 */
	resume(source) {
		source = this._coalesceSource(source);
		if (this._paused && this._pauseUntilSource <= source) {
			this._paused = false;
			this._pauseUntilSource = TTS_SOURCES.UNSPECIFIED;
			this._control?.play();
		}
		return this;
	}

	/**
	 * Sets or toggles whether TTS should be enabled, which roughly correlates to the
	 * "mute" control in the UI.
	 * @param {boolean?} val
	 * @returns
	 */
	setEnabled(val) {
		if ('undefined' === typeof (val) || null === val) {
			this._enabled = !this._enabled;
		} else {
			this._enabled = !!val;
		}

		this._control?.pause();
		this._clear();

		if (!this._enabled) {
			this._onEnd(false);
		}

		return this;
	}

	_shouldNotPlay(instance) {
		return (
			!this._control || // not initialized properly
			!this.enabled || // not enabled
			(
				this._paused && // paused and the pause level overrides the speech's source
				this._sourceOverrides(this._pauseUntilSource, instance.source)
			)
		);
	}

	/**
	 * Public interface for telling TTS to speak
	 * @param {TTS_SOURCES} source The source of the speech
	 * @param {string} text The text to speak
	 * @param {string=} voice The (optional) voice code to use
	 * @returns
	 */
	speak(source, text, voice) {
		this._log('speak()', [source, text, voice], 'enabled:', this.enabled, 'onHold:', this._paused)

		source ||= TTS_SOURCES.UNSPECIFIED;
		text = text?.trim()?.toLowerCase();

		if (!this.enabled || !text) {
			return this;
		}

		const newInstance = new TextToSpeechInstance(text, { source: source, voice: voice });

		this._log('startingQueue', this._queue);
		// remove any queued speech that is the same or lower weight as the new one
		this._queue = this._queue.filter(i => this._sourceOverrides(i.source, newInstance.source));
		this._queue.push(newInstance);
		this._log('afterQueue', this._queue);

		this._log('_currentInstance', this._currentInstance);

		if (this._sourceOverrides(this._currentInstance?.source, newInstance.source)) {
			return this; // Don't interrupt
		}

		this._control?.pause();
		this._currentInstance = null;
		return this._speakNext();
	}

	_speakNext() {
		const instance = this._queue.shift();
		if (instance) {
			this._currentInstance = instance;
			this._cacheAudioUrl(instance)
				.then(() => { this._play(instance); })
				.catch((_) => {
					if (this._currentInstance == instance) {
						this._onEnd(false);
					}
				});
		}
		return this;
	}

	/**
	 * Compares two sources' priority, to see if b should override a
	 * @param {TTS_SOURCES} a
	 * @param {TTS_SOURCES} b
	 */
	_sourceOverrides(a, b) {
		return a > b;
	}

}

export { TextToSpeechSpeaker, TTS_SOURCES }
