import { openInviteDialog } from '../../header-menu/invite.es6.js';
import { TranslatedMessage } from './TranslatedMessage.mjs';
import { TranslatedMessageGroup } from './TranslatedMessageGroup.mjs';
import { TranslatedMessageParagraph } from './TranslatedMessageParagraph.mjs';

/**
 * Update the message grouping to include new AI-related messages.
 * @param {TranslatedMessageGroup[]} prevGroups
 * @param {TranslatedMessage[]} newMessages
 * @returns {TranslatedMessageGroup[]}
 */
const groupMessages = (prevGroups, newMessages) => {
	let messages = prevGroups.flatMap(g => g.messages);
	messages.push(...newMessages);
	const groups = messages.reduce((groups, m) => {
		const id = m.isQuestion ? m._id : m.questionId;
		(groups[id] = groups[id] || []).push(m);

		return groups;
	}, {});

	return Object.values(groups)
		.map(g => new TranslatedMessageGroup({ messages: g }));
}

/**
 * Take actions based on an array of functions returned by the AI (if any). Functions for the viewer to handle.
 * @param {any} functions
 * @returns {boolean}
 */
const handleAiFunctions = (functions) => {
	let actionsTaken = false;
	functions?.map(f => {
		if (f.params) {
			f.params = JSON.parse(f.params);
		}
		switch (f.name) {
			case 'applyNow':
			case 'bookTour':
				actionsTaken = true;
				if (f.params?.url) {
					window.open(f.params.url, '_blank', 'noreferrer');
				}
				break;
			case 'invite':
				actionsTaken = true;
				openInviteDialog(f.params);
				break;
			case 'contact':
				actionsTaken = true;
				window.mobileWebController.chatView.toggleChatWindow(true);
				window.mobileWebController.chatController.sendChatMessage("I'd like to speak to someone about this property.");
				break;
			case 'showMap':
				window.mobileWebController.openDrawerForMapSearch(f.params.places || "my location");
				break;
			default:
				console.warn('[ai]', `Unhandled AI action ${f.name}.`, Date.now() * .001);
				break;
		}
	});
	return actionsTaken;
};

/**
 * If a user message is a command handled before going to the AI, invoke it and return true.
 * @returns {boolean} If the command was a non-AI command.
 */
const handleNonAiCommand = (message) => {
	switch (message?.toLowerCase()) {
		case 'pause':
		case 'stop':
			window?.mobileWebController?.pauseAutoTour();
			return true;
		case 'play':
		case 'resume':
			window?.mobileWebController?.resumeAutoTour();
			return true;
	}
	return false;
};

const navigateToContentItem = (lciid) => {
	window.mobileWebController?.setSelectedContentItem(
		`ListingContentItem:${lciid}`,
		{ updateContentArea: true, updateScene: true }
	);
	// TODO: return true/false if the featureId is known locally or not.
};

const navigateToFirstFeature = (message) => {
	if (message?.featureIds?.length) {
		message.selectedFeatureIndex = 0;
		navigateToContentItem(message.featureIds[0]);
		return true;
	}
	return false;
};

/**
 * Navigate to the next distinct feature
 * @param {TranslatedMessage} message
 * @returns {boolean} If the next feature is in a hidden paragraph that is now unhidden or any other UI change needed.
 */
const navigateToNextFeature = (message) => {
	if (message?.nextableFeatureIds?.length > 1) {
		if (message.selectedFeatureIndex < 0) {
			message.selectedFeatureIndex = 0;
			navigateToContentItem(message.currentFeatureId);
			return false;
		} else {
			const currentFeatureId = message.currentFeatureId;
			const featuresNextFirst = [...message.featureIds.slice(message.selectedFeatureIndex + 1), ...message.featureIds.slice(0, message.selectedFeatureIndex + 1)];
			const nextFeatureIndex = (featuresNextFirst.findIndex((v) => v != currentFeatureId) + message.selectedFeatureIndex + 1) % message.featureIds.length;
			message.selectedFeatureIndex = nextFeatureIndex;
			navigateToContentItem(message.currentFeatureId);
			const targetParagraph = message.selectedFeatureParagraph;
			if (targetParagraph?.isHidden) {
				targetParagraph.isHidden = false;
				return true;
			}
		}
	}
	return false;
};

/**
 * Parse feature (listing content item) IDs out of an AI paragraph.
 * @param {any} answer
 * @returns
 */
const parseFeatureIds = (answer) => {
	if (!answer) {
		return [];
	}
	const lciidRegex = /<i class="lci" lciid="(\d+)">/g;
	return [...answer.matchAll(lciidRegex)].map(match => match[1]);
}


/**
 * Translate a message from the AIDB to format used here by the view.
 * @param {any} message
 * @returns {TranslatedMessage}
 */
const translateMessage = (message) => {
	if (!message) { return null; }
	const isAnswer = !!message?.answerId || !!message?.answer;
	return new TranslatedMessage({
		_id: message._id ?? (isAnswer ? message.answerId : message.messageId ?? message.questionId),
		problem: message.problem,
		isAnswer,
		...(isAnswer && { questionId: message.questionId ?? message.messageId }),
		paragraphs: translateParagraphs(message),
		suggestions: message.suggestions
	});
};

const translateParagraphs = (message) => {
	let content = message?.question?.userQuestion;
	if (content || content === '') {
		return [new TranslatedMessageParagraph({ html: content })];
	} else {
		content = message?.answer || message?.message;
		return content.split('</p>').map(p => p.replace(/<p[^>]*>/, '').trim()).filter(p => p).map((p) => {
			return new TranslatedMessageParagraph({
				html: p,
				featureIds: parseFeatureIds(p)
			});
		});
	}
};


export { groupMessages, handleAiFunctions, handleNonAiCommand, navigateToContentItem, navigateToFirstFeature, navigateToNextFeature, parseFeatureIds, translateMessage, translateParagraphs }
