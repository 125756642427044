import intializeRoomsChannel from "./rooms-channel.js"
import { IrUtils } from "../utils/utils.es13.js"
import { getStreetViewStaticMedia, translateStreetviewZoomToFov, normalizeHeading, snapPitch } from "../utils/map-utils.es6"
import { ArrayStreamTween, TweenValueBoundary } from "../utils/animation.es6"
import { Easing } from "../utils/animation-easing.es6"
import { IguideViewer } from "../viewer/iguide-viewer.es13.js"
import { EveryScapeViewer } from "../viewer/everyscape-viewer.es13.js"

const upstreamTelemetryPeriodMs = window.siteSettings.TelemetryUpstreamPeriodSeconds * 1000.0;
const sceneTransitionTimeoutMs = window.siteSettings.SceneTransitionTimeoutSeconds * 1000.0;

const matterportOptions = {
	defaultTransitionTimeMs: window.siteSettings.Matterport.Transition.DefaultSeconds * 1000,
	preSweepDelay: {
		backoffRatio: window.siteSettings.Matterport.PreSweepDelay.BackoffRatio, // Increase by this ratio each failure from default up to max.
		defaultMs: window.siteSettings.Matterport.PreSweepDelay.DefaultSeconds * 1000,
		maxRetries: window.siteSettings.Matterport.PreSweepDelay.MaxRetries,
		maxMs: window.siteSettings.Matterport.PreSweepDelay.MaxSeconds * 1000
	}
};
matterportOptions.preSweepDelay.currentMs = matterportOptions.preSweepDelay.defaultMs;

const getTelemetryRoomId = function () {
	return window.siteSettings.TelemetryRoomIdFormat.replace('{0}', window.roomId);
};

const matterportSdkKey = window.siteSettings.MatterportShowcaseSdkKey;
const olioApiBaseUrl = window.siteSettings.OlioApiBaseUrl;
const olioMediaBaseUrl = window.siteSettings.OlioMediaBaseUrl;

let state = {
	currentViewerType: null,
	currentView: { viewerType: null },
	loaded: { camera: false, model: false, playing: false },
	matterportSdk: null,
	streetviewViewer: null,
	olioViewer: null,
	videoViewer: null,

	lastScenePerViewer: new Map(), // string viewerType => view

	// Being guided.
	movingQueue: [],
	movingTo: null
};

function getListingLocation() {
	const listing = mobileWebController.contentModel.getSelectedListing();
	if (listing && listing.location && listing.location.calculated) {
		return {
			calculated: {
				lat: listing.location.calculated.lat,
				lng: listing.location.calculated.lng
			}
		};
	} else {
		return null;
	}
}

let iguideViewer = null;
let everyscapeViewer = null;

function translateMatterportViewToTelemetry(view) {
	let cp = `matterport://model/${view.modelId}`;
	if (view.sweep) {
		cp += `/sweep/${view.sweep}`;
	}
	let telemetryView = {
		content: {
			contentPath: cp
		}
	};
	if (view.position) {
		telemetryView.viewerPosition = [view.position.x, view.position.y, view.position.z];
	}
	if (view.rotation) {
		telemetryView.content.heading = -(view.rotation?.y);
		if (view.position) {
			telemetryView.viewerPosition.push(view.rotation.x);
			telemetryView.viewerPosition.push(view.rotation.y);
			telemetryView.viewerPosition.push(view.zoom);
		}
	}
	return telemetryView;
}

function translateOlioViewToTelemetry(view) {
	const parts = view.url.split('/');
	const telemetryView = {
		content: {
			contentPath: `olio://container/${parts[3]}/file/${parts[4]}`
		}
	};
	if (view.center) {
		telemetryView.viewerPosition = [view.center?.x, view.center?.y, view.zoom];
	}
	
	return telemetryView;
}

function translateVideoViewToTelemetry(view) {
	const parts = view.descriptorUrl.split('/');
	const telemetryView = {
		content: {
			contentPath: `video://container/${parts[3]}/file/${parts[4]}`
		}
	};
	telemetryView.viewerPosition = [view.seconds || 0, view.isPlaying ? 1 : 0, 0, 0];

	return telemetryView;
}

function translateStreetviewViewToTelemetry(view) {
	return {
		content: {
			contentPath: `streetview://panorama/${view.panoId}`,
			heading: normalizeHeading(view.heading),
			location: {
				calculated: { lat: view.lat || 0, lng: view.lng || 0 }
			}
		},
		viewerPosition: [
			normalizeHeading(view.heading), snapPitch(view.pitch), view.zoom || 1, view.lat || 0, view.lng || 0
		]
	};
}

function translateMatterportTelemetryToView(view) {
	const cp = view.content.contentPath.split('/');
	const newView = {
		viewerType: 'matterport',
		modelId: cp[3],
		sweep: cp.length > 5 ? cp[5] : null
	};
	if (view.viewerPosition && (view.viewerPosition.length >= 5)) {
		newView.position = { x: view.viewerPosition[0], y: view.viewerPosition[1], z: view.viewerPosition[2] };
		newView.rotation = { x: view.viewerPosition[3], y: view.viewerPosition[4] };
		newView.zoom = view.viewerPosition[5] || 1
	};
	return newView;
}

function translateOlioTelemetryToView(view) {
	const parts = view.content.contentPath.split('/');
	const vp = view.viewerPosition ? view.viewerPosition : [0, 0, 1];
	const translatedView = {
		viewerType: 'olio',
		url: `${olioMediaBaseUrl}/${parts[3]}/${parts[5]}`,
		center: {
			x: vp[0],
			y: vp[1]
		},
		zoom: vp[2]
	};
	return translatedView;
}

function translateVideoTelemetryToView(view) {
	const parts = view.content.contentPath.split('/');
	const vp = view.viewerPosition ? view.viewerPosition : [0, undefined, 0, 0];
	const translatedView = {
		viewerType: 'video',
		descriptorUrl: `${olioMediaBaseUrl}/${parts[3]}/${parts[5]}`,
		seconds: vp[0] || 0
	};
	if (vp[1]) {
		translatedView.isPlaying = true;
	} else if (vp[1] === 0) {
		translatedView.isPlaying = false;
	}
	return translatedView;
}

function translateStreetviewTelemetryToView(view) {
	const vp = view.viewerPosition ? view.viewerPosition : [0, 0, 1];
	return {
		viewerType: 'streetview',
		panoId: view.content?.contentPath?.substring(22),
		heading: normalizeHeading(vp[0]),
		pitch: snapPitch(vp[1]),
		zoom: vp[2] || 1
	};
}

function translateView(view) {
	if (!view) return null;

	if (view.viewerType) {
		let telemetry = null;
		switch (view.viewerType) {
			case 'everyscape':
				telemetry = everyscapeViewer?.translateSceneToTelemetry(view);
				break;
			case 'iguide':
				telemetry = iguideViewer?.translateSceneToTelemetry(view);
				break;
			case 'matterport':
				telemetry = translateMatterportViewToTelemetry(view);
				break;
			case 'olio':
				telemetry = translateOlioViewToTelemetry(view);
				break;
			case 'streetview':
				telemetry = translateStreetviewViewToTelemetry(view);
				break;
			case 'video':
				telemetry = translateVideoViewToTelemetry(view);
				break;
			default:
				telemetry = null;
				break;
		}
		if (telemetry?.content && !telemetry.content.location) {
			telemetry.content.location = getListingLocation();
		}
		return telemetry;
	} else {
		const cp = view.content?.contentPath || '';
		const contentType = cp.split(':', 2)[0];
		switch (contentType) {
			case 'everyscape':
				return everyscapeViewer?.translateTelemetryToScene(view);
			case 'iguide':
				return iguideViewer?.translateTelemetryToScene(view);
			case 'matterport':
				return translateMatterportTelemetryToView(view);
			case 'olio':
				return translateOlioTelemetryToView(view);
			case 'streetview':
				return translateStreetviewTelemetryToView(view);
			case 'video':
				return translateVideoTelemetryToView(view);
			default:
				return null;
		}
	}
}

function initializeUpstreamTelemetry() {
	setInterval(function () {
		try {
			const ypm = window.mobileWebController ? window.mobileWebController.youPipModel : null;
			let telemetry = {
				call_state: ypm ? ypm.participantState.toCompressedString() : '1000000',
				guide_follow: window.mobileWebController ? window.mobileWebController.guideState.guideFollow : null,
				client_instance_id: window.clientInstanceId,
				view: translateView(state.currentView),
				profile: IrUtils.shallowStripNullies({ name: ypm ? ypm.displayName : '', avUrl: ypm && ypm.avatarPhotoUrl ? ypm.avatarPhotoUrl : null })
			};
			const listing = (window.mobileWebController && window.mobileWebController.contentModel) ?
				window.mobileWebController.contentModel.getSelectedListing() : null;
			if (listing) {
				telemetry.page = { listing: { Id: listing.Id } };
			}
			window.roomsChannel.send({ method: 'set-telemetry', params: telemetry });
			if (state.movingTo && state.movingTo.added && (new Date().getTime() - state.movingTo.added) > sceneTransitionTimeoutMs) {
				state.movingTo = null;
			}
			window.mobileWebController.handleScenePotentiallyChanged(telemetry.view);
		
		} catch (error) {
			console.error('[telemetry]', 'Error sending telemetry.', error);
		}
	}, upstreamTelemetryPeriodMs);
}

function initializeMatterportViewer() {
	$('#matterport-viewer').on('load', function () {
		if (state.matterportSdk) {
			state.matterportSdk.disconnect();
			delete state.matterportSdk;
		}
		window.MP_SDK.connect(
			$('#matterport-viewer').get(0),
			matterportSdkKey,
			''
		).then(function (sdk) {
			state.matterportSdk = sdk;
			state.loaded = { camera: false, delay: false, model: true, playing: false };
			sdk.App.state.subscribe(function (s) {
				if (sdk.App.Phase.PLAYING === s.phase) {
					state.loaded.playing = true;
					processSceneChangeStep();
				}
			});
			sdk.Camera.pose.subscribe(function (pose) {
				state.loaded.camera = true;
				setTimeout(function () {
					state.loaded.delay = true; processSceneChangeStep();
				}, matterportOptions.preSweepDelay.currentMs);
				if (state.currentView.viewerType === 'matterport') {
					state.currentView.position = { x: pose.position.x, y: pose.position.y, z: pose.position.z };
					state.currentView.rotation = { x: pose.rotation.x, y: pose.rotation.y };
					state.currentView.sweep = pose.sweep;
					processSceneChangeStep();
				}
			});

			sdk.Camera.zoom.subscribe(function (zoom) {
				if (state.currentView.viewerType === 'matterport') {
					state.currentView.zoom = zoom?.level || 1;
					processSceneChangeStep();
				}
			});

			sdk.Sweep.current.subscribe(function (sweep) {
				if (!sweep || !sweep.sid) {
					return;
				}
				if (state.currentView.viewerType === 'matterport') {
					state.currentView.sweep = sweep.sid;
					processSceneChangeStep();
				}
			});
			sdk.Sweep.data.subscribe({
				onCollectionUpdated: function (sweepData) {
					state.sweepData = sweepData;
					processSceneChangeStep();
				}
			});
		});
	});
}

function initializeStreetviewViewer() {
	window.addEventListener('resize', () => {
		if (state.streetviewViewer) {
			google.maps.event.trigger(state.streetviewViewer, 'resize');
		}
	});
}

function initializeTelemetryAndViewers() {
	console.log(`[telemetry] Entering room ${getTelemetryRoomId()} as ${window.clientInstanceId}`, Date.now() * .001);

	window.telemetryRoomId = getTelemetryRoomId();
	intializeRoomsChannel(window.telemetryRoomId);
	iguideViewer = new IguideViewer(state, $('#iguide-viewer'), window.siteSettings.iguide);
	everyscapeViewer = new EveryScapeViewer(state, $('#everyscape-viewer'), window.siteSettings.everyscape);
	initializeMatterportViewer();
	initializeStreetviewViewer();
	initializeUpstreamTelemetry();
}

// Quick function for being within 1% lat and 1% lng (not bothering with gcd).
function rotationsClose(a, b) {
	if (!a || !b) { // If either doesn't have rotations defined
		return !a && !b;
	}
	let alt = (a.y < b.y) ? (a.y + 360 - b.y) : (b.y + 360 - a.y);
	return (Math.abs(a.x - b.x) < 1.8) &&
		Math.min(Math.abs(a.y - b.y), alt) < 3.6;
}

function povsClose(a, b) {
	if ('undefined' === typeof (a.heading) || 'undefined' === typeof (b.heading)) {
		return a.heading === b.heading;
	}
	return (Math.abs(a.heading - b.heading) < 5) &&
		Math.abs(a.pitch - b.pitch) < 5;
}

function zoomsClose(a, b) {
	const aZoom = a || 1;
	const bZoom = b || 1;
	const zoomRatio = (aZoom > bZoom) ? (aZoom / bZoom) : (bZoom / aZoom);
	return zoomRatio < 1.1;
}

function viewsEqual(a, b) {
	if (a?.viewerType !== b?.viewerType) { return false; }
	switch (a.viewerType) {
		case 'everyscape':
			return everyscapeViewer?.areScenesClose(a, b) ?? false;
		case 'iguide':
			return iguideViewer?.areScenesClose(a, b) ?? false;
		case 'matterport':
			return a.modelId === b.modelId &&
				a.sweep === b.sweep &&
				rotationsClose(a.rotation, b.rotation) &&
				zoomsClose(a.zoom, b.zoom);
		case 'streetview':
			return a.panoId === b.panoId && povsClose(a, b) && zoomsClose(a.zoom, b.zoom);
		case 'olio':
			return areOlioScenesClose(a, b);
		default:
			return false;
	}
}

function streetviewSceneChanged() {
	let cv = state.currentView;
	let v = state.streetviewViewer;
	if (cv.viewerType === 'streetview') {
		cv.panoId = v.getPano();
		let pov = v.getPov();
		if (pov) {
			cv.heading = pov.heading;
			cv.pitch = pov.pitch;
			cv.zoom = pov.zoom;
		}
		if (v.position && v.position.lat && v.position.lng) {
			cv.lat = v.position.lat();
			cv.lng = v.position.lng();
		} else if (v.location && v.location.latLng) {
			cv.lat = v.location.latLng.lat();
			cv.lng = v.position.latLng.lng();
		}
		setTimeout(processSceneChangeStep, 0);
	}
}

function loadStreetView(panoId) {
	state.currentView = { panoId: panoId, viewerType: 'streetview' };
	if (!state.streetviewViewer) {
		state.streetviewViewer = new google.maps.StreetViewPanorama(
			$('#streetview-viewer').get(0), { disableDefaultUI: true, motionTracking: false, motionTrackingControl: false, pano: panoId });
		state.streetviewViewerLoaded = true;
		state.streetviewViewer.addListener('status_changed', streetviewSceneChanged);
		state.streetviewViewer.addListener('position_changed', streetviewSceneChanged);
		state.streetviewViewer.addListener('pov_changed', streetviewSceneChanged);
		state.streetviewViewer.addListener('zoom_changed', streetviewSceneChanged);
	} else {
		state.streetviewViewer.setPano(panoId);
	}
}

function loadModel(modelId) {
	state.currentView = { modelId: modelId, viewerType: 'matterport' };
	state.loaded = { camera: false, delay: false, model: false, playing: false };
	const newContentPath = translateMatterportViewToTelemetry(state.movingTo);
	let url = 'https://my.matterport.com/show?' +
		$.param({
			applicationKey: matterportSdkKey,
			brand: 0,
			dh: 0,
			gt: 0,
			help: 0,
			hl: 2,
			hr: 0,
			log: 0,
			m: modelId,
			measurements: 0,
			mls: 2,
			mt: 0,
			play: 1,
			search: 0,
			qs: 1,
			ts: -1,
			useLegacyIds: IrUtils.matterportUseLegacyIds(newContentPath.content.contentPath) ? 1 : 0
		});
	$('#matterport-viewer').attr('src', url);
}

function setSweep(view) {
	let to = {
		transition: state.matterportSdk.Sweep.Transition.INSTANT
	};
	if (view.position) {
		$.extend(to, { x: view.position.x, y: view.position.y, z: view.position.z });
	}
	if (state.sweepData) {
		let fromSweepData = state.sweepData[state.currentView.sweep];
		let toSweepData = state.sweepData[view.sweep];
		if (fromSweepData && toSweepData && fromSweepData.neighbors.includes(view.sweep)) {
			to = {
				transition: state.matterportSdk.Sweep.Transition.FLY,
				transitionTime: matterportOptions.defaultTransitionTimeMs
			};
		}
	}
	return state.matterportSdk.Sweep.moveTo(view.sweep, to);
}

function performViewerSwitchIfNecessary() {
	const currentViewerType = state.currentViewerType;
	const newViewerType = state.movingTo.viewerType;
	if (currentViewerType !== newViewerType) {
		if (currentViewerType) { // Save
			state.lastScenePerViewer.set(currentViewerType, state.currentView);
			if (currentViewerType === 'streetview' && state.streetviewViewer) {
				state.streetviewViewer.animation = null;
			}
		}
		state.currentViewerType = newViewerType;
		const lastSceneForNewViewer = state.lastScenePerViewer.get(newViewerType);
		if (lastSceneForNewViewer) {
			state.currentView = lastSceneForNewViewer;
		}
		if (currentViewerType) {
			$(`#${currentViewerType}-viewer`).hide();
		}
		if (newViewerType) {
			$(`#${newViewerType}-viewer`).show();
		}
		if (newViewerType === 'streetview') {
			setTimeout(() => {
				google.maps.event.trigger(state.streetviewViewer, 'resize');
			}, 1500);
		}
		if (currentViewerType === 'video') {
			state.videoViewer.pause();
		}
		if ([newViewerType, currentViewerType].includes('everyscape')) {
			everyscapeViewer?.toggleActive('everyscape' === newViewerType);
		}
		if ([newViewerType, currentViewerType].includes('iguide')) {
			iguideViewer?.toggleActive('iguide' === newViewerType);
		}
	}
}

function processSceneChangeStep() {
	if (!state.movingTo && state.movingQueue.length > 0) {
		state.movingTo = state.movingQueue.pop();
	}
	if (!state.movingTo) {
		return;
	}

	performViewerSwitchIfNecessary();
	if (everyscapeViewer?.viewerType === state.movingTo.viewerType) {
		return everyscapeViewer.processSceneChange(state.movingTo);
	} else if (iguideViewer?.viewerType === state.movingTo.viewerType) {
		return iguideViewer.processSceneChange(state.movingTo);
	} else  if (state.movingTo.viewerType === 'matterport') {
		return processMatterportSceneChangeStep();
	} else if (state.movingTo.viewerType === 'streetview') {
		return processStreetviewSceneChangeStep();
	} else if (state.movingTo.viewerType === 'olio') {
		return processOlioSceneChangeStep();
	} else if (state.movingTo.viewerType === 'video') {
		return processVideoSceneChangeStep();
	}
}

function processStreetviewSceneChangeStep() {
	let newView = state.movingTo;
	let currentView = state.currentView;

	if (newView.panoId !== currentView.panoId) {
		if (state.streetviewViewer) {
			state.streetviewViewer.animation = null;
		}
		loadStreetView(newView.panoId);
		setTimeout(state.doneProcessingViewRequest, 1000);
		return;
	}
	if (!viewsEqual(currentView, newView)) {
		function viewToArray(view) {
			const h = view.heading || 0;
			const p = view.pitch || 0;
			const z = view.zoom || 1;
			return [h, p, z];
		}
		if (state.streetviewViewer?.animation && !state.streetviewViewer.animation.complete) {
			state.streetviewViewer.animation.retarget(viewToArray(newView), 500);
		} else if (state.streetviewViewer) {
			const animation = new ArrayStreamTween(
				viewToArray(currentView),
				viewToArray(newView),
				500,
				Easing.algs.easeInOutQuad,
				Date.now(),
				new Map([[0, new TweenValueBoundary(0, 360, true)]])
			);
			state.streetviewViewer.animation = animation;
			let doNextFrame = function () {
				if (!animation.complete && animation === state.streetviewViewer?.animation) {
					const thisFrame = state.streetviewViewer.animation.getValues();
					state.streetviewViewer.setPov({ heading: thisFrame[0], pitch: thisFrame[1] });
					state.streetviewViewer.setZoom(thisFrame[2]);
					setTimeout(doNextFrame, 65); // 65ms ~ 15fps
				}
			}
			doNextFrame();
		}
	}
	state.doneProcessingViewRequest();
}

function olioViewChanged() {
	state.olioViewer.GetView().then((view) => {
		if (state.currentView?.viewerType === 'olio') {
			state.currentView.url = view.url;
			state.currentView.center = { x: view.position?.x, y: view.position?.y };
			state.currentView.zoom = view.position?.z;
			processSceneChangeStep();
		}
	});
}

function loadOlio(view) {
	if (!state.olioViewer) {
		state.olioViewer = EveryScape.Olio.Viewer({ container: $('#olio-viewer').get(0), background: '#000', url: view.url });
		state.olioViewer.on('ContentChanged', olioViewChanged);
		state.olioViewer.on('ViewChanged', olioViewChanged);
	} else {
		state.olioViewer.SetImageUrl(view.url);
	}
	state.lastRequestedOlioScene = { center: { x: 0, y: 0 }, url: view?.url, zoom: 1 };
	state.currentView = { viewerType: 'olio', url: view?.url };
}

/**
 * Are the center of a and b scenes within 10 pixels in each direction and ~10% zoom?
 * @param {any} a
 * @param {any} b
 * @return {boolean}
 */
function areOlioScenesClose(a, b) {
	const ax = a?.center?.x || 0;
	const bx = b?.center?.x || 0;
	const ay = a?.center?.y || 0;
	const by = b?.center?.y || 0;
	return (Math.abs(ax - bx) < 10)
		&& (Math.abs(ay - by) < 10)
		&& zoomsClose(a?.zoom, b?.zoom);
}

/**
 * All the commands have been given to the viewer for the latest "setView" call, so pop it off the stack and
 * potentially start the latest setView request.
 */
state.doneProcessingViewRequest = function () {
	state.movingTo = null;
	setTimeout(processSceneChangeStep, 0);
	return state;
};

function processOlioSceneChangeStep() {
	let newView = state.movingTo;

	if (!newView) {
		return;
	}
	if (newView.url !== state.lastRequestedOlioScene?.url) {
		loadOlio(newView);
	} else {
		if (!areOlioScenesClose(newView, state.lastRequestedOlioScene)) {
			// Send the request to change center and zoom and save that that request was sent to the viewer.
			// Due to screen size differences, might not be able to change to the exact center, so rely on the
			// last command sent to the viewer, to determine if another SetPosition needs to be sent, not
			// what the viewer reports as the center.
			state.lastRequestedOlioScene.center = { x: newView?.center?.x || 0, y: newView?.center?.y || 0 };
			state.lastRequestedOlioScene.zoom = newView?.zoom || 1;
			const req = state.lastRequestedOlioScene;
			state.olioViewer.SetPosition(req.center.x, req.center.y, req.zoom);
		}
		state.doneProcessingViewRequest();
	}
}

function areVideoScenesClose(a, b) {
	if (a?.descriptorUrl !== b?.descriptorUrl) {
		return false;
	}
	return Math.abs((a?.seconds || 0) - (b?.seconds || 0)) < 1.5;
}

function onVideoSceneChanged() {
	if (state.currentView?.viewerType === 'video') {
		state.currentView.seconds = state.videoViewer.currentTime;
		state.currentView.isPlaying = state.videoViewer.playing ? true : false;

		const descriptorUrl = state.currentView.descriptorUrl;
		if (!state.videoViewerReady) {
			const v = state.videoViewer;
			state.videoViewerReady = true;
			setTimeout(() => {
				v.clear().then(() => {
					window.debugVideoViewer && console.debug('[video-viewer]', `Fetching video descriptor from ${descriptorUrl}.`, Date.now() * .001);
					return fetchVideoContentDescriptor(descriptorUrl)
				}).then((descriptor) => {
					return new Promise((resolve) => {
						setTimeout(() => {
							window.debugVideoViewer && console.debug('[video-viewer]', `Attaching sources and tracks.`, Date.now() * .001);
							const promises = [];
							state.videoDescriptor = descriptor;
							descriptor.poster && promises.push(v.setPoster(descriptor.poster));
							descriptor.sources && promises.push(v.addSources(descriptor.sources));
							descriptor.tracks && descriptor.tracks.forEach((track) => {
								promises.push(v.addTrack(track.src, track.kind, track.srclang, track.label, track.isDefault));
							});
							Promise.all(promises).then(() => { resolve(); });
						}, 0);
					});
				}).then(() => {
					setTimeout(() => {
						window.debugVideoViewer && console.debug('[video-viewer]', `Loading.`, Date.now() * .001);
						v.load().then(() => {
							const pollReadiness = function () {
								if (state.videoViewer.readyState() < 1) { // 1 is "video is now seekable"
									window.debugVideoViewer && console.debug('[video-viewer]', `Polling viewer readiness.`, Date.now() * .001);
									setTimeout(pollReadiness, 500);
								} else {
									setTimeout(() => {
										window.debugVideoViewer && console.debug('[video-viewer]', `Video is loaded. ready is ${state.videoViewer.readyState()}`, Date.now() * .001);
										state.videoLoaded = true;
										processSceneChangeStep();
									}, 0);
								}
							};
							pollReadiness();
						});
					}, 0);
				});
			}, 0);
		}
	}
	processSceneChangeStep();
}

function toAbsoluteUrl(url, defaultBaseUrl) {
	if (url.indexOf('://') > 0 || url.indexOf('//') === 0) {
		return url;
	} else {
		if (!defaultBaseUrl.endsWith('/')) {
			defaultBaseUrl += '/';
		}
		if (url.startsWith('/')) {
			url = url.substring(1);
		}
		return defaultBaseUrl + url;
	}
}

function fetchVideoContentDescriptor(url) {
	return new Promise(function (resolve, reject) {
		var xhr = new XMLHttpRequest();
		xhr.responseType = 'json';
		xhr.addEventListener('load', function () {
			if (xhr.status == 200) {
				let contentType = xhr.getResponseHeader("Content-Type");
				if (contentType == "application/json") {
					// Translate local URLs to absolute URLs
					var response = xhr.response;
					var baseUrl = url.substring(0, url.lastIndexOf('/'));

					if (response.poster) {
						response.poster = toAbsoluteUrl(response.poster, baseUrl);
					};

					if (response.sources && response.sources.length > 0) {
						for (var i = 0; i < response.sources.length; i++) {
							if (response.sources[i].src) {
								response.sources[i].src = toAbsoluteUrl(response.sources[i].src, baseUrl);
							}
						}
					}

					if (response.tracks && response.tracks.length > 0) {
						for (var i = 0; i < response.tracks.length; i++) {
							if (response.tracks[i].src) {
								response.tracks[i].src = toAbsoluteUrl(response.tracks[i].src, baseUrl);
							}
						}
					}

					resolve(response);
				} else {
					reject("XHR returned unexpected content-type for URL " + url);
				}
			} else {
				reject("XHR returned status " + xhr.status + " for URL " + url);
			}
		});
		xhr.addEventListener('error', reject);
		xhr.open('GET', url, true);
		xhr.send();
	});
}

function loadVideo(view) {
	if (!state.videoViewer) {
		state.videoViewer = EveryScape.Corpus.VideoViewer({ container: $('#video-viewer').get(0) });
		state.videoViewerElement = $('#video-viewer video').get(0);
		if (window.siteSettings.Scene.Video.TouchToTogglePlay) {
			$('#video-viewer').on('touchstart', (evt) => {
				if (evt.target.tagName === 'VIDEO') {
					state.videoViewer.playing ? state.videoViewer.pause() : state.videoViewer.play();
				}
			});
		}
		state.videoViewer.events.on('ImplementationReady', onVideoSceneChanged);
		state.videoViewer.events.on('Loaded', onVideoSceneChanged);
		state.videoViewer.events.on('CurrentTimeChanged', onVideoSceneChanged);
		state.videoViewer.events.on('Suspended', state.videoViewer.pause);
		state.videoViewer.events.on('ViewerHiding', state.videoViewer.pause);
		state.videoViewer.events.on('PresentationStopped', state.videoViewer.pause);
	} else {
		state.videoViewer.pause();
		state.videoViewer.setPlaybackTime(0);
		state.videoViewerReady = false;
	}
	state.lastRequestedVideoScene = { descriptorUrl: view.descriptorUrl };
	state.currentView = { viewerType: 'video', descriptorUrl: view.descriptorUrl };
	state.videoDescriptor = null;
	state.videoLoaded = false;
}

function processVideoSceneChangeStep() {
	let newView = state.movingTo;

	if (!newView) {
		return;
	}
	if (newView.descriptorUrl !== state.lastRequestedVideoScene?.descriptorUrl) {
		loadVideo(newView);
	} else if (state.videoLoaded) {
		if (state.videoDescriptor) {
			if (!areVideoScenesClose(newView, state.currentView) || (('undefined' !== typeof(newView.isPlaying)) && (newView.isPlaying !== state.currentView.isPlaying))) {
				state.videoViewer.setPlaybackTime(newView.seconds).then(() => {
					if (newView.isPlaying) { state.videoViewer.play(); }
					else if (newView.isPlaying === false) { state.videoViewer.pause(); }
				});
			}
			state.doneProcessingViewRequest();
		}
	}
}

/**
 * If there's been an OOPS, increase the time between determining the matterport library has loaded it's metadata and
 * when setSweep is called for the first time. In order to give matterport SDK time to actually load the model content (images).
 * Do exponential backoff (25%) up to a max time.
 * @return {number} resultant pre-sweep delay in milliseconds.
 */
function adjustMatterportPreSweepDelayOnError() {
	const psd = matterportOptions.preSweepDelay;
	psd.currentMs = Math.min(psd.currentMs * psd.backoffRatio, psd.maxMs);
	return psd.currentMs;
}

/**
 * The model has been loaded and a pano (sweep) has been requested that's different than the pano
 * currently in view.
 */
function _processMatterportSceneChangeStepSweep() {
	const newView = state.movingTo;

	// Awaiting this to be filled in by matterport sdk.
	if (!state.sweepData || !state.sweepData[state.currentView.sweep] || !state.sweepData[newView.sweep]) { return; } 

	if (!newView.moveToTries) { newView.moveToTries = 0; }
	++(newView.moveToTries);

	window.debugMatterportViewer && console.debug('[matterport]', `moveTo(${newView.sweep}) from ${state.currentView.sweep}.`, Date.now() * .001);
	newView.moveToPending = true; // Only send command once (except on error retries).
	setSweep(newView).then(() => {
		newView.moveToPending = false;
		window.debugMatterportViewer && console.debug('[matterport]', `moveTo(${newView.sweep}) complete. Current sweep is ${state.currentView.sweep}.`, Date.now() * .001);
		setTimeout(processSceneChangeStep, 0);
	}, (error) => {
		newView.moveToPending = false;
		window.debugMatterportViewer && console.debug('[matterport]', `moveTo(${newView.sweep}) error: ${JSON.stringify(error)}`, Date.now() * .001);
		if (error.includes('disconnected')) {
			// Ignore "SDK client was disconnected before Sweep.moveTo was fulfilled".
			// That error means the operation was intentionally interrupted.
			if (newView === state.movingTo) {
				state.doneProcessingViewRequest();
			}
			return;
		}
		const action = (newView.moveToTries <= matterportOptions.preSweepDelay.maxRetries) ? 'oops_detected:trying' : 'oops_detected:fatal';
		console.error('[scene]', action, error, Date.now() * .001);
		const delayMs = adjustMatterportPreSweepDelayOnError();
		if (newView.moveToTries <= matterportOptions.preSweepDelay.maxRetries) {
			setTimeout(processSceneChangeStep, delayMs);
		} else {
			if (newView === state.movingTo) {
				state.doneProcessingViewRequest(); // Retries used up. Failed to change scene.
			}
		}
	});
}

function _processMatterportSceneChangeStepRotation() {
	const newView = state.movingTo;

	window.debugMatterportViewer && console.debug('[matterport]', `setRotation(${JSON.stringify(newView.rotation)})`, Date.now() * .001);
	newView.setRotationPending = true;
	state.matterportSdk.Camera.setRotation(newView.rotation).then(() => {
		newView.setRotationPending = false;
		window.debugMatterportViewer && console.debug('[matterport]', `setRotation(${JSON.stringify(newView.rotation)}) complete. Current rotation is ${JSON.stringify(state.currentView.rotation)}.`, Date.now() * .001);
		setTimeout(processSceneChangeStep, 0);
	}, (error) => {
		newView.setRotationPending = false;
		window.debugMatterportViewer && console.debug('[matterport]', `setRotation(${JSON.stringify(newView.rotation)}) error: ${JSON.stringify(error)}`, Date.now() * .001);
		if (newView === state.movingTo) {
			state.doneProcessingViewRequest();
		}
	});
}

function _processMatterportSceneChangeStepZoom() {
	const newView = state.movingTo;
	window.debugMatterportViewer && console.debug('[matterport]', `zoomTo(${newView.zoom})`, Date.now() * .001);
	newView.zoomToPending = true;
	state.matterportSdk.Camera.zoomTo(newView.zoom).then(() => {
		newView.zoomToPending = false;
		if (newView === state.movingTo) {
			state.doneProcessingViewRequest();
		}
	}, (error) => {
		newView.zoomToPending = false;
		window.debugMatterportViewer && console.debug('[matterport]', `zoomTo(${newView.zoom}) error: ${JSON.stringify(error)}`, Date.now() * .001);
		if (newView === state.movingTo) {
			state.doneProcessingViewRequest();
		}
	});
}

/**
 * Called when "something relevant" has change in either requesting a new view or
 * receiving events about the matterport viewer.
 * Process the steps to go from state.currentView to state.movingTo (the currently desired view to transition to).
 * 1. Load a new model, if necessary.
 * 2. Load (sweep to) the desired pano, if necessary. Wait for the moveTo() command to complete _and_ the viewer
 * be viewing that pano.
 * 3. Set the camera rotation, if necessary. Wait for the matterport setRotation() commant to resolve and for the
 * camera position to be close enough to desired.
 * 4. Set the zoom, if necessary. Wait for the matterport zoomTo() command to resolve.
 * 5. Call doneProcessingViewRequest() to pop the next queued view request and do it.
 */
function processMatterportSceneChangeStep() {
	/**
	 * A requested matterport scene/view.
	 * Also holds "task" related variables used during the processing of the request.
	 * boolean moveToPending
	 * number moveToTries - number of attempts at moveTo
	 * boolean setRotationPending
	 * boolean zoomToPending
	 */
	const newView = state.movingTo;
	const currentView = state.currentView;

	const modelsDiffer = !!newView.modelId && (newView.modelId !== currentView.modelId);
	const sweepFormatsDiffer = newView.sweep && currentView.sweep && newView.sweep.length !== currentView.sweep.length;

	if (modelsDiffer) {
		console.debug(['processMatterportSceneChangeStep'], currentView?.sweep, newView?.sweep);
		return loadModel(newView.modelId);
	}

	// Wait for model to be ready and outstanding commands to complete before proceeding with more scene change steps.
	if (!state.loaded.model || !state.loaded.delay || !state.loaded.camera || !state.loaded.playing ||
		newView.moveToPending || newView.setRotationPending || newView.zoomToPending)
	{
		return;
	}

	// At this point the model is loaded and ready for further commands.
	if (!newView.sweep) { // Just loading the model, so we're done.
		return state.doneProcessingViewRequest();
	}

	const sameSweep = (newView.sweep === currentView.sweep);
	if (!sameSweep) {
		return _processMatterportSceneChangeStepSweep();
	}

	// At this point the model and pano/sweep are correct.
	if (!newView.rotation) { // Just loading the model and pano, so we're done.
		return state.doneProcessingViewRequest();
	}

	if (!rotationsClose(currentView.rotation, newView.rotation)) {
		return _processMatterportSceneChangeStepRotation();
	}

	// At this point model, pano, rotation are all set.
	if (!newView.zoom || zoomsClose(currentView.zoom, newView.zoom)) {
		return state.doneProcessingViewRequest();
	}

	return _processMatterportSceneChangeStepZoom();
}

function setView(view) {
	let last = (state.movingQueue.length > 0) ? state.movingQueue[state.movingQueue.length - 1] : state.movingTo;
	if (!last || !viewsEqual(last, view)) {
		const copy = view?.clone ? view.clone() : $.extend(true, {}, view);
		copy.added = new Date().getTime();
		state.movingQueue[0] = copy;
		processSceneChangeStep();
	}
}

function setViewWithConversion(view) {
	let convertedView = translateView(view);
	if (convertedView) {
		setView(convertedView);
	}
}

function getViewerState() {
	return state;
}

function getCurrentTelemetry() {
	return translateView(state.currentView);
}

async function uploadMatterportThumbnail(nameParts) {
	const sdk = state.matterportSdk;

	const dataUrl = await sdk.Renderer.takeScreenShot({ width: 500, height: 500 }, { mattertags: false, sweeps: true })

	const postUrl = `${olioApiBaseUrl}/matterport-sphere/${nameParts.join('/')}`;

	const formdata = new FormData();

	if (dataUrl) {
		const blobBin = atob(dataUrl.split(',')[1]);
		const arr = [];
		for (let i = 0; i < blobBin.length; i++) {
			arr.push(blobBin.charCodeAt(i));
		}
		const file = new Blob([new Uint8Array(arr)], { type: 'image/jpeg' });
		formdata.append('image', file);

		$.ajax({
			url: postUrl,
			type: 'post',
			contentType: false,
			data: formdata,
			error: console.error,
			processData: false,
			success: console.log
		});
	}
}

function getMatterportThumbnail() {
	const cv = state.currentView;

	const modelId = cv.modelId.toLowerCase();
	const sweepId = cv.sweep.toLowerCase();
	const x = Math.round(cv.rotation.x + 90).toString();
	const y = Math.round(cv.rotation.y + 180).toString();
	const z = Math.round(cv.zoom?.level ?? 1).toString();

	uploadMatterportThumbnail([modelId, sweepId, x, y, z]);

	return `${olioMediaBaseUrl}/mpmodel-${modelId}/thumb-${sweepId}_${x}_${y}_${z}`;
}

function getOlioThumbnail(view) {
	return `${view.url}_thumb.jpg`;
}

function getThumbnail(view) {
	view = view ?? state.currentView;
	let thumbnailUrl = null;
	switch (view.viewerType) {
		case 'olio':
			thumbnailUrl = getOlioThumbnail(view);
			break;
		case 'streetview':
			thumbnailUrl = getStreetViewStaticMedia(view.panoId, view.heading, view.pitch, translateStreetviewZoomToFov(view.zoom));
			break;
		case 'matterport':
			// Matterport generation can only work on the currently displayed view
			if (view === state.currentView) {
				thumbnailUrl = getMatterportThumbnail();
			}
			break;
		case 'video':
			thumbnailUrl = state.videoDescriptor.poster;
			break;
	}
	return thumbnailUrl;
}

export {
	getThumbnail,
	getViewerState,
	initializeTelemetryAndViewers,
	setView,
	setViewWithConversion,
	translateView,
	getCurrentTelemetry
}
