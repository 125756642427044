import { ChatroomControllerStates } from "./chatroom-controller.es6.js"
import { doToast, TOAST_TYPES } from "../utils/toastr-wrapper.es6.js"

const ChatroomViewErrorMessages = {
	unsupported: 'Unable to connect to call. This browser is not supported.',
	devices_denied: 'Unable to connect to call. Please allow microphone/camera access in your browser.',
	no_token: 'Unable to connect to call. Unable to get twilio access token.',
	twilio_tracks: 'Failed to connect to call. Failed to access microphone/camera.',
	twilio_connect: 'Failed to connect to call.',
	twilio_connect_nre: 'Failed to connect to call. Make sure your camera and/or microphone are not in use.',
	no_telemetry: 'Unable to connect to call at this time.',
	screen_share_not_allowed: 'Failed to share screen. Please stop sharing screen in other applications.',
	screen_share_failed: 'Failed to share screen.',
	disconnected_error: 'You have been disconnected from the call.',
	default: 'Unable to connect to call.'
};

function getPip(id) {
	return $('.pip').filter((_i, el) => { return $(el).data('id') === id; }).first();
}

/**
 * @param {ChatroomController} chatroomController Dependency injection.
 * @param {EveryScape.SyncV2.UI} mainView Dependency injection. Uses audioLevelBar, hideWidgetsForSharedScreen, togglePips.
 * @param {ChatroomUtils} chatroomUtils Dependency injection.
 */
function ChatroomView(chatroomController, mainView, chatroomUtils) {
	this._chatroomController = chatroomController;
	this._mainView = mainView;
	this._chatroomUtils = chatroomUtils;
	this._lastChatIdConnectedTo = null;

	// During the preview/connect/devices modal - as the user switches device selections, there's a delay between the
	// switch and the mediastream being attached. Before proceeding after confirm/cancel, wait for the mediastreams
	// so as to close them properly.
	this._pendingGetUserMedia = {
		audio: new Map(),
		audioDeviceIdSelected: null,
		video: new Map(),
		videoDeviceIdSelected: null,
		clearSelected: function () { this.audioDeviceIdSelected = this.videoDeviceIdSelected = null; return this; },
		count: function () { return this.audio.size + this.video.size; }
	}; // Semaphore logic.

	this._showingDevicesModal = false;

	chatroomController.onError = function (id) {
		var message = ChatroomViewErrorMessages[id];
		if (!message) {
			message = ChatroomViewErrorMessages['default'];
		}
		doToast(TOAST_TYPES.error, message);
	};

	// Hookup to the controller.
	var me = this;
	chatroomController.onShowPermissionsModal = function () { me.showPermissionsModal(); };
	chatroomController.onShowDevicesModal = function (mediaDevices, defaultTrackOptions) { me.showDevicesModal(mediaDevices, defaultTrackOptions); };
	chatroomController.onDevicesChange = function (mediaDevices, defaultTrackOptions) {
		if (me._showingDevicesModal) {
			me._setupDeviceModalLists(mediaDevices, defaultTrackOptions);
		}
	};
	chatroomController.onConnected = function (localTracks) {
		me._setYouPipVideoTrack(localTracks.localVideoTrack);
		me.$callConnectButtons.addClass('connected').find('i').text('call_end');
		me.$callCancelButtons.prop('disabled', false).removeClass('disabled');
		me._lastChatIdConnectedTo = me._chatroomController.getChatId();
		const ps = window.mobileWebController.youPipModel.participantState;
		ps.callConnected = ps.audioBroadcasting = window.mobileWebController.headerMenuModel.callConnected = true;
		ps.videoBroadcasting = localTracks.localVideoTrack && localTracks.localVideoTrack.id;
		window.mobileWebController.headerMenuView.update();
		window.mobileWebController.pipsView.updatePip(window.clientInstanceId);
		window.mobileWebController._activityMonitor.joinCall();
	};
	chatroomController.onDominantSpeakerChanged = function (participantClientInstanceId) {
		$('#InnerPipsContainer .espip-avatar-image').removeClass('dominantSpeaker');
		if (participantClientInstanceId) { getPip(participantClientInstanceId).find('.espip-avatar-image').addClass('dominantSpeaker'); }
	};
	chatroomController.onDisconnected = function (wasConnected) {
		me._setYouPipVideoTrack(null);
		me.$callConnectButtons.removeClass('connected').find('i').text('call');
		me.$callCancelButtons.prop('disabled', true).addClass('disabled');
		window.mobileWebController.removeAllTracks();
		const ps = window.mobileWebController.youPipModel.participantState;
		ps.callConnected = ps.audioMuted = ps.audioBroadcasting = ps.videoMuted = ps.videoBroadcasting = window.mobileWebController.headerMenuModel.callConnected = false;
		chatroomController.onDominantSpeakerChanged(null);
		window.mobileWebController.headerMenuView.update();
		window.mobileWebController.pipsView.updatePip(window.clientInstanceId);
		if (wasConnected) { window.mobileWebController._activityMonitor.leaveCall(); }
		me.$modalBehindHolder.hide();
		me.$permissionsModal.hide();
		me.$modalShowWhileChoosingPermissions.hide();
		me.$devicesModal.hide();
	};

	// Since the voice call may be connected but the telemetry-created pip may not be, possibly create a pip.
	var attachPip = function (clientInstanceId, track, isAudio) {
		window.mobileWebController.attachTrack(clientInstanceId, track, isAudio);
	};

	chatroomController.onShowParticipant = function (clientInstanceId, videoTrack) {
		attachPip(clientInstanceId, videoTrack, false);
	};

	chatroomController.onHearParticipant = function (clientInstanceId, audioTrack) {
		attachPip(clientInstanceId, audioTrack, true);
	};

	chatroomController.onSelfMicrophoneEnabled = function (isEnabled) {
		me.$selfMicrophoneButtons.toggleClass('muted', !isEnabled);
		me.$selfMicrophoneButtonIcons.text(isEnabled ? 'mic' : 'mic_off');
		me.$devicesModalMicrophoneButton.text(isEnabled ? 'Unmuted' : 'Muted');
		me.$devicesModalAudioListHolder.toggleClass('disabled', !isEnabled);
		window.mobileWebController.headerMenuModel.audioMuted = window.mobileWebController.youPipModel.participantState.audioMuted = !isEnabled;
		window.mobileWebController.headerMenuView.update();
		window.mobileWebController.pipsView.updatePip(window.clientInstanceId);
	};

	chatroomController.onSelfCameraEnabled = function (isEnabled) {
		me.$selfCameraButtons.toggleClass('muted', !isEnabled);
		me.$selfCameraButtonIcons.text(isEnabled ? 'videocam' : 'videocam_off');
		me.$devicesModalCameraButton.text(isEnabled ? 'On' : 'Off');
		me.$devicesModalVideoList.toggleClass('disabled', !isEnabled);
		me.$devicesModalCameraDisabledMessage.toggle(!isEnabled);
		window.mobileWebController.headerMenuModel.videoMuted = window.mobileWebController.youPipModel.participantState.videoMuted = !isEnabled;
		window.mobileWebController.headerMenuView.update();
		window.mobileWebController.pipsView.updatePip(window.clientInstanceId);
	};

	chatroomController.onVideoDisabled = function (clientInstanceId, isDisabled) {
		getPip(clientInstanceId).find('.espip-video-track').toggle(!isDisabled);
	};
	chatroomController.onMuted = function (clientInstanceId, isMuted) {
	};

	// Potentially accessed before ready().
	me.$selfMicrophoneButtons = me.$selfMicrophoneButtonIcons = me.$devicesModalMicrophoneButton = me.$devicesModalAudioListHolder =
		me.$selfCameraButtons = me.$selfCameraButtonIcons = me.$devicesModalCameraButton = me.$devicesModalVideoList =
		me.$devicesModalCameraDisabledMessage = $('');

	// Find rendered DOM elements now, since they're rendered by page ready, rather than always searching the DOM every time they're used.
	$(document).ready(function () {
		me.$modalBehindHolder = $('#ModalHolderBehindLoader');
		me.$permissionsModal = $('#ModalWeNeedPermissions');
		me.$permissionsModalDialog = me.$permissionsModal.find('.modal-dialog');
		me.$permissionsModalDismiss = $('#ModalWeNeedPermissionsDismiss');
		me.$permissionsModalDismiss.unbind('click').click(function () { me._onPermissionsModalDismiss(); });
		me.$permissionsModalCancel = $('#ModalWeNeedPermissionsCancel');
		me.$permissionsModalCancel.unbind('click').click(function () {
			me.$permissionsModal.hide();
			me._chatroomController.disconnect({ ignoreState: true });
		});
		me.$permissionsCancelButton = $('#ModalCancelPermissions');
		me.$devicesModal = $('#ModalShowAudioAndVideoInputs');
		me.$devicesModalAudioListHolder = $('#AudioDeviceListHolder');
		me.$devicesModalAudioList = $('#AudioDevicesList');
		me.$devicesModalVideoList = $('#VideoDevicesList');
		me.$devicesModalVideoPreview = $('#VideoDevicePreview');
		me.$devicesModalConnectButton = $('#ModalShowAudioAndVideoInputsConfirm');
		me.$devicesModalConnectButton.unbind('click').click(function () { me._onDevicesModalConfirm(); return true; });
		me.$devicesModalCancelButton = $("#ModalShowAudioAndVideoInputsCancel");
		me.$devicesModalCancelButton.unbind('click').click(function () { me._onDevicesModalCancel(); return true; });
		me.$callConnectButtons = $('#ChatCallBtn,#MobileChatCallBtn,#MobileCallBtn');
		me.$callCancelButtons = $('#ChatCallCancelBtn,#MobileChatCallCancelBtn,#MobileCallCancelBtn');
		me.$selfMicrophoneButtons = $('#YouToggleMic,#MobileYouToggleMic,#DialogMuteVoip,#YouToggleMicTwo');
		me.$selfMicrophoneButtonIcons = $('#YouToggleMic i,#MobileYouToggleMic i,#DialogMuteVoip i,#YouToggleMicTwo i');
		me.$devicesModalMicrophoneButton = $('#DialogMuteVoip h15');
		me.$selfMicrophoneButtons.unbind('click').click(function () { me._chatroomController.toggleSelfMicrophone(); return true; });
		me.$selfCameraButtons = $('#YouToggleVideo,#MobileYouToggleVideo,#DialogToggleCameraVoip');
		me.$selfCameraButtonIcons = $('#YouToggleVideo i,#MobileYouToggleVideo i,#DialogToggleCameraVoip i');
		me.$selfCameraButtons.unbind('click').click(function () { me._chatroomController.toggleSelfCamera(); return true; });
		me.$devicesModalCameraButton = $('#DialogToggleCameraVoip h15');
		me.$devicesModalCameraDisabledMessage = $('#VideoDevicePreview .muted-message');
		me.$body = $('body');
		me.$modalHolderBehindLoader = $("#ModalHolderBehindLoader");
		me.$modalShowWhileChoosingPermissions = $("#ModalShowWhileChoosingPermissions");

		const holders = '#AudioDeviceListHolder,#VideoDevicesListHolder';
		$(holders).unbind('click').click((evt) => { $(evt.target).parents(holders).toggleClass('expanded'); });
		chatroomController.onSelfMicrophoneEnabled(chatroomController.selfMicrophoneEnabled);
		chatroomController.onSelfCameraEnabled(chatroomController.selfCameraEnabled);
	});
};

ChatroomView.prototype._setYouPipVideoTrack = function (track) {
	mobileWebController.attachTrack(window.clientInstanceId, track, false);
	return this;
};

ChatroomView.prototype.showPermissionsModal = function () {
	this.$modalBehindHolder.show();
	this.$permissionsModal.show();
};

ChatroomView.prototype._onPermissionsModalDismiss = function () {
	this.$modalShowWhileChoosingPermissions.show();
	this.$permissionsModal.hide();
	this._chatroomController.handlePermissionsModalDismissed();
};

/**
 * When the devices modal is cancelled, cleanup the media streams and cancel the connection attempt.
 * @private
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._onDevicesModalCancel = function () {
	var me = this;

	me._showingDevicesModal = false;
	me.$devicesModal.hide();

	me._detachDevicesModalAudioStream();
	me._detachDevicesModalVideoStream();

	// Wait for any pending getUserMedia() requests for mic/camera to complete
	// before calling the chatroomController to continue the connect process.
	if (0 === me._pendingGetUserMedia.count()) {
		me._chatroomController.handleDevicesModalCancel();
	} else {
		$(me._pendingGetUserMedia).one('out', function () {
			me._chatroomController.handleDevicesModalCancel();
		});
	}

	return me;
};

/**
 * Get the list of selected mic/camera devices from the devices modal.
 * @private
 * @returns {Array} array of devices that have been selected.
 */
ChatroomView.prototype._getSelectedMediaDevices = function () {
	return [
		this.$devicesModalAudioList.find('.active').data('device'),
		this.$devicesModalVideoList.find('.active').data('device')
	]
		.filter(function (el) { return 'undefined' !== typeof (el); });
};

/**
 * When the user clicks the connect/reconnect button, cleanup the media streams and continue the connection attempt.
 * @private
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._onDevicesModalConfirm = function () {
	var me = this;

	me._showingDevicesModal = false;
	me.$devicesModal.hide();

	var mediaDevices = me._getSelectedMediaDevices();

	me._detachDevicesModalAudioStream();
	me._detachDevicesModalVideoStream();

	if (0 === me._pendingGetUserMedia.count()) {
		me._chatroomController.handleDevicesModalConfirm(mediaDevices);
	} else {
		$(me._pendingGetUserMedia).one('out', function () {
			me._chatroomController.handleDevicesModalConfirm(mediaDevices);
		});
	}
};

/**
 * Display the "devices" modal with a list of microphones and cameras.
 * @param {any} mediaDevices { microphones: [devices], cameras: [devices], permissions: [] }
 * @param {any} defaultTrackOptions optional { audio: {deviceId: id}, video: {deviceId: id} }
 * @returns {ChatroomView} this
 */
ChatroomView.prototype.showDevicesModal = function (mediaDevices, defaultTrackOptions) {
	var me = this;

	// Setup the modal.
	me._showingDevicesModal = true;
	me._pendingGetUserMedia.clearSelected();

	me._setupDeviceModalLists(mediaDevices, defaultTrackOptions);

	const buttonText = (me._lastChatIdConnectedTo !== me._chatroomController.getChatId()) ?
		me.$devicesModalConnectButton.data('conn') : me.$devicesModalConnectButton.data('reconn');
	me.$devicesModalConnectButton.text(buttonText);
	me.$devicesModal.show();
	me.$modalHolderBehindLoader.hide();
	me.$modalShowWhileChoosingPermissions.hide();
	me.$devicesModalCancelButton.toggle(ChatroomControllerStates.UPDATING_MEDIA_DEVICES !== me._chatroomController.getState());

	return me;
};

/**
 * Setup ths lists of devices in the devices modal.
 * @param {any} mediaDevices { microphones: [devices], cameras: [devices], permissions: [] }
 * @param {any} defaultTrackOptions optional { audio: {deviceId: id}, video: {deviceId: id} }
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._setupDeviceModalLists = function (mediaDevices, defaultTrackOptions) {
	var me = this;

	defaultTrackOptions = $.extend({}, { audio: {}, video: {} }, defaultTrackOptions);

	me._setupConnectModalAudioList(mediaDevices.microphones, defaultTrackOptions.audio.deviceId);
	me._setupConnectModalVideoList(mediaDevices.cameras, defaultTrackOptions.video.deviceId);

	return me;
};

/**
 * Detach the microphone from the audioBar.
 * @private
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._detachDevicesModalAudioStream = function () {
	var me = this;

	/* TODO
	me._mainView.audioLevelBar.stop();
	me._mainView.audioLevelBar.setMediaTrack(null);
	*/
	return me;
};

/**
 * Detach the camera from the preview area of the devices modal.
 * @private
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._detachDevicesModalVideoStream = function () {
	var me = this;

	var currentVideoStream = me.$devicesModalVideoPreview.data('videoStream');
	if (currentVideoStream) {
		currentVideoStream.getTracks().forEach(function (track) {
			track.stop();
		});
		me.$devicesModalVideoPreview.removeData('videoStream')
	}
	me.$devicesModalVideoPreview.find('video').remove();

	return me;
};

/**
 * Given the selected $div to the mic or camera selection, get a media stream for it and attach it to
 * the audioBar or video preview.
 * @private
 * @param {any} $div
 * @param {any} isAudio
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._enableAndAttachMediaDeviceStreamInDevicesModal = function ($div, isAudio) {
	var me = this;

	isAudio ? me._detachDevicesModalAudioStream() : me._detachDevicesModalVideoStream();

	if (0 === $div.length) {
		return me;
	}

	var device = $div.data('device');
	if (!device) {
		return me;
	}

	var deviceId = device.deviceId;
	var pendingMap = isAudio ? me._pendingGetUserMedia.audio : me._pendingGetUserMedia.video;
	if (pendingMap.has(deviceId)) {
		return me;
	}

	var promise;
	if (isAudio) {
		me._pendingGetUserMedia.audioDeviceIdSelected = deviceId;
		promise = me._chatroomUtils.getMediaStream({ audio: { deviceId: device.deviceId } });
		pendingMap.set(deviceId, {});

		promise = promise.then(function (mediaStream) {
			pendingMap.delete(deviceId);
			if (me._showingDevicesModal && (deviceId === me._pendingGetUserMedia.audioDeviceIdSelected)) {
/* TODO				me._mainView.audioLevelBar.setMediaTrack({ mediaStreamTrack: mediaStream.getTracks()[0] }); */
			} else {
				// There was a pending getMediaStream() request when cancel/connect was selected
				// Clean up the track right here.
				mediaStream.getTracks().forEach(function (track) {
					track.stop();
				});
			}
		}, function (error) {
			pendingMap.delete(deviceId);
			console.error(`Failed to attach to microphone ${deviceId} in the devices modal. ${error.name}: ${error.message}`);
		});
	} else {
		me._pendingGetUserMedia.videoDeviceIdSelected = deviceId;
		promise = me._chatroomUtils.getMediaStream({ video: { deviceId: device.deviceId } });
		pendingMap.set(deviceId, {});

		promise = promise.then(function (mediaStream) {
			pendingMap.delete(deviceId);
			if (me._showingDevicesModal && (deviceId === me._pendingGetUserMedia.videoDeviceIdSelected)) {
				var $video = $('<video autoplay playsinline>');
				me.$devicesModalVideoPreview.append($video);
				$video[0].srcObject = mediaStream;
				me.$devicesModalVideoPreview.data('videoStream', mediaStream);
			} else {
				// There was a pending getMediaStream() request when cancel/connect was selected
				// Clean up the track right here.
				mediaStream.getTracks().forEach(function (track) {
					track.stop();
				});
			}
		}, function (error) {
			pendingMap.delete(deviceId);
			console.error(`Failed to attach to camera ${deviceId} in the devices modal. ${error.name}: ${error.message}`);
		});
	}
	promise.finally(function () {
		// When the outstanding getUserMedia() requests complete - trigger an event so that
		// we continue to the cancel or connect flow.
		if (0 === me._pendingGetUserMedia.count()) {
			$(me._pendingGetUserMedia).trigger('out');
		}
	});

	return me;
};

/**
 * When the user selects a mic or camera, update the list and enable and attach the
 * media stream to the audio bar or
 * @private
 * @param {any} evt event from the click handler on the mic/camera list.
 * @param {boolean} isAudio
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._selectMediaDeviceListItem = function (evt, isAudio) {
	var me = this;

	var $target = $(evt.target).closest('.deviceItem').first();
	if ($target.hasClass('active')) {
		return me; // Nothing to do, they selected the current item.
	}

	if (isAudio) {
		me.$devicesModalAudioList.find('.active').removeClass('active');
	} else {
		me.$devicesModalVideoList.find('.active').removeClass('active');
	}
	$target.addClass('active');

	me._enableAndAttachMediaDeviceStreamInDevicesModal($target, isAudio);

	return me;
};

/**
 * Create a div element to fill in either the audio or video list in the devices modal.
 * @private
 * @param {any} device
 * @param {boolean} isAudio
 * @returns {object} jQuery HTML element for the device's item in the list.
 */
ChatroomView.prototype._createMediaDeviceListItem = function (device, isAudio) {
	var me = this;

	var $div = $('<div>').attr('title', device.label).addClass('deviceItem')
		.addClass(isAudio ? 'audioItem' : 'videoItem')
		.data('device', device); // Attach the media device to the div.
	$div.append($('<i>').addClass('material-icons').text(isAudio ? 'mic' : 'videocam'));
	$div.append($('<span>').text(device.label).addClass('oneLineText'));
	$div.append($('<i>').text('expand_more').addClass('material-icons'));

	$div.unbind('click').click(function (evt) { me._selectMediaDeviceListItem(evt, isAudio); return true; });

	return $div;
};

/**
 * In the list of microphones or cameras, visually select and mark active the default device.
 * @private
 * @param {any} $list jQuery HTML element for the list of devices.
 * @param {string} defaultDeviceId optional
 * @returns {object} jQuery HTML element for the default device.
 */
ChatroomView.prototype._activateDefaultMediaDeviceListItem = function ($list, defaultDeviceId) {
	$list.find('.active').removeClass('active');

	if (!defaultDeviceId) {
		defaultDeviceId = 'default';
	}

	var $active = $('');

	$list.find('.deviceItem').each(function (_i, item) {
		var $item = $(item);
		var device = $item.data('device');
		if (device && (defaultDeviceId === device.deviceId)) {
			$active = $item;
			return false;
		}
		return true;
	});

	if (0 === $active.length) {
		// In the case of defaultDeviceId passed in and not found... default is _always_ the first in the list anyways.
		$active = $list.find('.deviceItem').first();
	}

	return $active.first().addClass('active');
};

/**
 * Create the list of audio devices in the devices modal.
 * @private
 * @param {any} microphones array of devices
 * @param {string} defaultDeviceId optional
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._setupConnectModalAudioList = function (microphones, defaultDeviceId) {
	var me = this;

	var $list = this.$devicesModalAudioList;

	me._detachDevicesModalAudioStream();
	$list.empty();

	microphones.forEach(function (mic) {
		if (mic.label && /aggregate/i.test(mic.label)) {
			// Don't allow aggregate audio devices.
			// They require user setup and almost certainly haven't been setup (just adding to user confusion).
			return;
		}
		$list.append(me._createMediaDeviceListItem(mic, true));
	});
	me._enableAndAttachMediaDeviceStreamInDevicesModal(me._activateDefaultMediaDeviceListItem($list, defaultDeviceId), true);

	return me;
};

/**
 * Create the list of video devices in the devices modal.
 * @private
 * @param {any} cameras array of devices
 * @param {string} defaultDeviceId
 * @returns {ChatroomView} this
 */
ChatroomView.prototype._setupConnectModalVideoList = function (cameras, defaultDeviceId) {
	var me = this;

	var $list = this.$devicesModalVideoList;

	me._detachDevicesModalVideoStream();
	$list.empty();

	cameras.forEach(function (cam) {
		$list.append(me._createMediaDeviceListItem(cam, false));
	});
	me._enableAndAttachMediaDeviceStreamInDevicesModal(me._activateDefaultMediaDeviceListItem($list, defaultDeviceId), false);

	return me;
};

export { ChatroomView }
