import React, { useState, useRef, useEffect, useLayoutEffect, useContext } from "react";
import { ChatContext } from "../../contexts";
import SendIcon from "../../components/icons/SendIcon";
import CancelIcon from "../../components/icons/CancelIcon";
import MuteIcon from "../../components/icons/MuteIcon";
import { SpeechToText } from '../../speech/speech-to-text.mjs';
import { AiApi } from "../../api/ai-service-api.es13";
import { MessageGroup } from './MessageGroup.jsx';
import { TranslatedMessage } from './TranslatedMessage.mjs';
import { groupMessages, handleAiFunctions, handleNonAiCommand, navigateToFirstFeature, navigateToNextFeature, translateMessage } from './utils.js';
import AiThinking from "../../components/AiThinking";
import classNames from "classnames";
import { TTS_SOURCES } from "../../speech/text-to-speech.mjs";

const ai = new AiApi();

const defaultInitialMessageText = `Hi, I'm the Infinityy AI, your 24/7 leasing agent. Discover your perfect space with tailored guidance.
Tell me what you are interested in seeing, and I will guide you through the property and neighborhood, with a virtual experience customized just for you.`;

export const AiChat = () => {
	const { debug, setDebug, setSubmitChatMessageFn } = useContext(ChatContext);
	const [buttonIsMic, setButtonIsMic] = useState(true);
	const [isRecording, setIsRecording] = useState(false);
	const [isThinking, setIsThinking] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [isMuted, setIsMuted] = useState(!window.mobileWebController.ttsEnabled);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
	const [submitEnabledTimeoutId, setSubmitEnabledTimeoutId] = useState(null);

	const inputFromMicrophone = () => {
		setButtonIsMic(true);
		setIsRecording(true);
		window.mobileWebController?.pauseTts();
		stt.current.record();
	};

	const [groups, setGroups] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [scrollInstant, setScrollInstant] = useState(true);
	const [scrollToGroupIndex, setScrollToGroupIndex] = useState(null);
	const [typingIndex, setTypingIndex] = useState([-1, -1, -1]); // GroupIndex, MessageIndex, ParagraphIndex
	const inputRef = useRef(null);
	const inputButtonRef = useRef(null);
	const messagesContainerRef = useRef(null);
	const stt = useRef(null);

	const scrollToBottomWithEffect = behavior =>
		[100, 300, 500].map(delay => setTimeout(
			() => window.requestAnimationFrame(() =>
				messagesContainerRef.current.scrollTo({
					top: messagesContainerRef.current.scrollHeight,
					behavior
				})
			),
			delay));

	useEffect(() => {
		let initialMessageText = defaultInitialMessageText;
		const aiSettings = window.contentModel?.aiSettings;
		let promptSuggestions = [];
		if (aiSettings) {
			initialMessageText = [aiSettings.Intro1, aiSettings.Intro2]
				.filter(t => t)
				.join('\n') || defaultInitialMessageText;
			promptSuggestions = [aiSettings.Prompt1, aiSettings.Prompt2, aiSettings.Prompt3]
				.filter(p => p)
		}
		if (!promptSuggestions.length) {
			promptSuggestions = ['Tour the building amenities', 'Show me nearby restaurants', 'Where can I walk my dog?'];
		}
		const initialMessage = TranslatedMessage.fromStringAnswer(initialMessageText, promptSuggestions);
		let newGroups = null;
		ai.getHistory().then((r) => {
			newGroups = groupMessages(groups, [initialMessage, ...r.map((m) => translateMessage(m))]);
		}).catch((ex) => {
			console.error('[ai]', 'Error retrieving AI chat history.', ex, Date.now() * .001);
			newGroups = groupMessages(groups, [initialMessage]);
		}).finally(() => {
			setIsLoading(false);
			newGroups.at(-1).isExpanded = true;
			if (1 == newGroups.length) {
				setTypingIndex([0, 0, 0]); // Start typing the welcome message.
			}
			setGroups(newGroups);
			updateAutoTour(newGroups, false);
		});
		stt.current = new SpeechToText({
			transcriptionDoneCallback: endMicrophoneInput,
			pauseWaitMs: Number.POSITIVE_INFINITY,
			initialPauseWaitMs: 60000
		});
		if (!stt.current?.isSupported) {
			setButtonIsMic(false);
		}
		stt.current.onUpdate = (text) => {
			setInputValue(text);
		};

		setSubmitChatMessageFn(() => submitInput);
 
		return () => {
			if (submitEnabledTimeoutId) {
				clearTimeout(submitEnabledTimeoutId);
			}
		}
	}, []);

	useEffect(() => {
		if (window.mobileWebController) {
			window.mobileWebController.onAudioSettingsChange = (enabled) => {
				setIsMuted(!enabled);
				if (enabled && groups.length === 1) {
					window.bottomDrawerView?.handleTtsAiResponseIntro(defaultInitialMessageText);
				}
			}
		}
	}, [groups]);

	useEffect(() => {
		inputRef.current.value = inputValue;
	}, [inputValue]);

	const updateAutoTour = (groups, resume) => {
		const lastMessage = groups?.at(-1)?.messages?.at(-1);
		const lastParagraphHtml = lastMessage?.paragraphs?.at(-1).html;

		// Exclude the buttons at the end
		const trimmedMessage = lastParagraphHtml.replace(/<br\/><i[\s\S]*/i, '');
		// Targeting the first <br> after the last <i class="lci">
		const outroMatch = trimmedMessage.match(/(?:<i class="lci"[^>]*>)(?!.*<i class="lci")[\s\S]*?<br\/?>([\s\S]*)/);
		const outroMessage = outroMatch ? outroMatch[1].trim() : '';

		if (lastMessage?.nextableFeatureIds?.length) {
			window?.mobileWebController?.startAutoTour(
				lastMessage.nextableFeatureIds,
				resume,
				outroMessage
			);
		} else {
			window?.mobileWebController?.stopAutoTour();
		}
	}

	useEffect(() => {
		if (messagesContainerRef.current) {
			if (scrollInstant) {
				scrollToBottomWithEffect("instant");
				setScrollInstant(false);
			} else {
				scrollToBottomWithEffect("smooth");
			}
		}
	}, [isLoading, isThinking]);

	useLayoutEffect(() => {
		if (scrollToGroupIndex && messagesContainerRef.current) {
			const group = messagesContainerRef.current.children[scrollToGroupIndex];
			group?.scrollIntoView({ behavior: 'smooth' });
			setScrollToGroupIndex(null);
		}
	}, [scrollToGroupIndex]);

	const submitInput = async (message) => {
		message = message?.trim();
		resetInput();

		if (!message || handleNonAiCommand(message)) {
			return;
		}

		if (message.toLowerCase() === "debug") {
			window?.mobileWebController?.pauseAutoTour();
			setDebug(!debug);
			return;
		}

		setButtonIsMic(stt.current?.isSupported);
		setIsRecording(false);
		window?.mobileWebController?.onAiInteraction();
		window.activityMonitor.aiInteracted();

		const tempQuestionId = `temp_${new Date().getTime()}`;

		setGroups(prevGroups => {
			// Collapse last group (and unhide all paragraphs) when sending message.
			if (prevGroups.length) {
				const last = prevGroups[prevGroups.length - 1];
				last.isExpanded = false;
				last.messages.map(m => m.paragraphs).flat().forEach(p => p.isHidden = false);
			}
			return groupMessages(
				prevGroups,
				[TranslatedMessage.fromQuestion(message, { _id: tempQuestionId })]
			);
		});
		setTypingIndex([-1, -1, -1]);
		setIsThinking(true);
		try {
			window.mobileWebController?.pauseTts(TTS_SOURCES.AI_INTRO);
			const jsonData = await ai.sendMessage(message);
			const translatedMessage = translateMessage({ ...jsonData.result, questionId: tempQuestionId });

			if (translatedMessage.paragraphs.length > 1) {
				// Only show first paragraph.
				translatedMessage.paragraphs.slice(1).forEach(p => p.isHidden = true);
			}

			if (jsonData?.result?.message) {
				window.bottomDrawerView?.handleTtsAiResponseIntro(jsonData.result.message);
			}
			navigateToFirstFeature(translatedMessage);
			handleAiFunctions(jsonData.result.functions);

			setGroups(prevGroups => {
				if (prevGroups.length) {
					// Unhide all prior paragraphs.
					prevGroups[prevGroups.length - 1].messages.map(m => m.paragraphs).flat().forEach(p => p.isHidden = false);
				}
				const newGroups = groupMessages(prevGroups, [translatedMessage]);
				if (newGroups.length) {
					const last = newGroups[newGroups.length - 1];
					// New group is expanded.
					last.isExpanded = true;
					setTypingIndex([newGroups.length - 1, last.messages.length - 1, 0]); // Start typing.
				}

				updateAutoTour(newGroups, true);

				return newGroups;
			});
		} finally {
			setIsThinking(false);
		}
	};

	const onInputKeyDown = e => {
		if (e.key === "Enter" || e.keyCode === 13) {
			e.preventDefault();
			submitInput(inputRef.current.value);
		}
	};

	const onInputChange = e => {
		setInputValue(inputRef.current.value);
		setButtonIsMic(stt.current?.isSupported && !inputRef.current.value);
	}

	const checkInputState = (value) => {
		if (isRecording) {
			endMicrophoneInput();
		} else if (value) {
			submitInput(value);
		} else {
			inputFromMicrophone();
		}
	};

	const resetInput = () => {
		// When clearing input textbox (hitting clear button, selecting an AI suggestion, finishing or
		// cancelling typing or mic input), focus the mic/send button so that the keyboard goes away.
		inputButtonRef?.current?.focus();

		setButtonIsMic(stt.current?.isSupported);
		setInputValue('');
	}

	const cancelInput = () => {
		stt.current.cancel();
		resetInput();
	}

	const toggleMute = () => {
		const mwc = window.mobileWebController;
		mwc.enableTts(!mwc.ttsEnabled);
		setIsMuted(!mwc.ttsEnabled);
	}

	const endMicrophoneInput = (text, error) => {
		stt.current.stop();
		setIsRecording(false);
		setButtonIsMic(stt.current?.isSupported);
		!!text && !error && submitInput(inputRef.current.value);
	};

	const exploreClick = () => window.mobileWebController.openDrawerForExplore();

	const micButtonClassName = classNames(
		"flex items-center flex-none justify-center p-5 rounded-full transition-colors duration-500 m-1 md:ml-2xl right-[4px] cursor-pointer border-none outline-none",
		{
			pulsing: isRecording,
			'opacity-50': !isSubmitEnabled
		},
	);
	const submitInputClassName = classNames(
		"flex items-center pl-2 pr-8 m-1 border border-solid rounded-full focus:outline-none placeholder:italic",
		{
			'opacity-50': !isSubmitEnabled
		}
	);

	return <div className="flex flex-col h-full">
		<div id="AiChatHistory"
			ref={messagesContainerRef}
			className={`flex flex-col ${isLoading ? 'items-center justify-center' : ''} flex-grow overflow-y-auto p-4 pt-0`}>
			<React.Fragment>
				{groups.map((group, i) =>
					<MessageGroup
						key={i}
						messages={group.messages}
						expanded={group.isExpanded}
						expandedCallback={(val) => {
							groups[i].isExpanded = !!val;
							groups[i].messages.map(m => m.paragraphs).flat().forEach(p => p.isHidden = false);
							setScrollToGroupIndex(i);
							setGroups(groups.slice());
							setTypingIndex([-1, -1, -1]); // Kill typing.
						}}
						moreCallback={(j) => {
							const gm = groups[i].messages[j];
							const k = gm.paragraphs.findIndex(p => p.isHidden);
							if (k >= 0) {
								gm.paragraphs[k].isHidden = false;
								setGroups(groups.slice());
								setTypingIndex([i, j, k]); // Type the next paragraph.
							}
						}}
						nextCallback={(j) => {
							navigateToNextFeature(groups[i].messages[j]);
							setGroups(groups.slice());
						}}
						typingMessageIndex={(typingIndex[0] === i) ? typingIndex[1] : -1}
						typingParagraphIndex={(typingIndex[0] === i) ? typingIndex[2] : -1}
						typingDoneCallback={() => setTypingIndex([-1, -1, -1])}
					/>)
				}
				{(isThinking || isLoading) && <div><AiThinking isVisible={isThinking} /></div>}
			</React.Fragment>
		</div>
		<div id="AiInteractionContainer" className="flex flex-shrink-0 items-center justify-between pt-0 w-full h-[50px]">
			<button id="AiExplore" onClick={exploreClick} className="flex items-center flex-none p-2 px-4 text-xs border-none rounded-full">
				Explore
			</button>
			<div className="relative flex items-center min-w-0">
				<input
					ref={inputRef}
					id="AiInput"
					disabled={!isSubmitEnabled}
					className={submitInputClassName}
					placeholder={isRecording ? 'Recording...' : 'Ask a question'}
					onKeyDown={onInputKeyDown}
					onKeyUp={onInputChange}
					onInput={onInputChange}
				/>
				{inputValue !== '' &&
					<button
						className="absolute flex items-center justify-center px-[7px] py-[5px] rounded-full border-none shadow-none cursor-pointer right-[7px]"
						type="button"
						onClick={() => cancelInput()}
					>
						<i className="text-sm material-icons">close</i>
					</button>
				}
			</div>
			<button
				id="AiTtsMute"
				className="flex items-center flex-none justify-center p-2 rounded-full transition-colors duration-500 m-1 md:ml-2xl right-[4px] cursor-pointer border-none"
				aria-label="Mute"
				type="button"
				onClick={() => toggleMute()}
			>
				<MuteIcon
					isMuted={isMuted}
					className={`transition-opacity duration-250`}
				/>
			</button>
			{isRecording &&
				<div className="fixed top-0 left-0 w-full h-full bg-black/70 z-1000">
					<div className="absolute bottom-0 w-full min-h-[20%] p-4 bg-[var(--brandMenuColor)] text-[var(--brandMenuPrimaryText)] flex flex-col items-stretch"
						onClick={() => checkInputState(inputValue)}>
						<button
							className="absolute right-0 flex items-center justify-center flex-none p-3 m-1 border-none rounded-full outline-none cursor-pointer z-1000 -top-8"
							aria-label="Cancel"
							onClick={() => cancelInput()}
						>
							<i className="material-icons">close</i>
						</button>
						<span className="flex-grow pb-5 text-xl">{inputValue || 'Listening...'}</span>
						<span className="flex-shrink-0 text-center">tap to send</span>
					</div>
				</div>
			}
			<button ref={inputButtonRef}
				id="AiInputSubmit"
				className={micButtonClassName}
				aria-label="Send"
				type="submit"
				disabled={!isSubmitEnabled}
				onClick={() => checkInputState(inputRef.current.value)}
			>
				<SendIcon
					isMic={buttonIsMic && !isRecording}
					className={`absolute ${isRecording ? 'text-infinityyTeal' : ''} transition-opacity duration-250`}
				/>
			</button>
		</div>
	</div>
};
